import { useEffect } from 'react';
import Slider from 'react-carousel-responsive';
import Container from '~/components/Layout/Container';
import { useInView } from 'react-intersection-observer';
import { useHeaderStore } from '~/store/header';
import 'react-carousel-responsive/dist/styles.css';
import bg1 from '~/img/bg-1.jpg';
import bg2 from '~/img/bg-2.jpg';
import bg3 from '~/img/bg-3.jpg';
import bg4 from '~/img/bg-4.jpg';

export default function Banner() {
  const headerStore = useHeaderStore();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      headerStore.setCurrentPosition('home');
    }
  }, [inView]);

  return (
    <div ref={ref} className="relative mb-12">
      <Slider autoplay={true} autoplaySpeed={3000} pauseOnHover={true}>
        <img src={bg1} alt="mainimg" />
        <img src={bg2} alt="mainimg" />
        <img src={bg3} alt="mainimg" />
        <img src={bg4} alt="mainimg" />
      </Slider>
      <Container>
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-max max-w-full z-10 px-10 "
          style={{ textShadow: '1px 1px #333333' }}
        >
          <div className="flex flex-col items-center justify-center text-white p-6 bg-black/20">
            <div className="font-bold mb-3 text-center text-xl xs:text-3xl mdl:text-4xl">
              <span className="features-vacuum-cleaner text-4xl" /> COMMERCIAL CLEANING SERVICES
            </div>
            <div className="xs:text-xl mdl:text-2xl">Keep That Dirt Away!</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
