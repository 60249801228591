import React from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';
import type { LoaderFunction } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Layout from '~/components/Layout';
import Index from '~/components/Pages/Index';
import Contact from '~/components/Pages/Contact';
import Logos from '~/components/Pages/Logos';
import FourOhFour from '~/components/Pages/404';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
ScrollTrigger.defaults({
  toggleActions: 'play pause resume reset',
});

const loader: LoaderFunction = async ({ request, params }): Promise<Response> => {
  let data = { error: false, data: '' };
  try {
    // const response = await fetch('http://localhost:3000/testimonials/testimonials.json');
    const response = await fetch('./testimonials/testimonials.json');
    if (response) {
      data.data = await response.json();
    } else {
      data.error = true;
      data.data = 'Unable to load testimonials';
    }
  } catch (error: unknown) {
    // console.log('ERROR', error);
    data.error = true;
    if (error instanceof SyntaxError) {
      data.data = (error as SyntaxError).message;
    } else {
      data.data = 'Unknown fetch error';
    }
  }
  return new Response(JSON.stringify(data), {
    status: 200,
    headers: {
      'Content-Type': 'application/json; utf-8',
    },
  });
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/contact" element={<Contact />} />
      <Route path="/logos" element={<Logos />} />
      <Route path="*" element={<FourOhFour />} />
      <Route index element={<Index />} loader={loader} />
    </Route>,
  ),
);

const App = (): JSX.Element => {
  return <RouterProvider router={router} />;
};

export default App;
