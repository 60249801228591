import { Parallax } from 'react-parallax';
import Container from '~/components/Layout/Container';
import importantImg from '~/img/bg-1.jpg';

export default function Important() {
  return (
    <Parallax
      bgImage={importantImg}
      strength={500}
      bgImageStyle={{ maxWidth: 'none', opacity: '.2' }}
      className="bg-brand-900"
    >
      <div style={{ height: '500px' }}>
        <Container>
          <div className="px-24 py-12 min-h-screen text-white">
            <div className="text-center text-3xl">NOTHING IS MORE IMPORTANT</div>
            <div className="flex justify-center my-6">
              <div className="border-b-2 border-brand-200 w-32" />
            </div>
            <div className="text-center text-xl mb-6">We prioritize the following...</div>
            <div className="flex justify-around mb-12">
              <div>
                <div className="text-center features-team text-5xl"></div>
                <div className="text-center">OUR CUSTOMERS</div>
              </div>
              <div>
                <div className="text-center features-leaf text-5xl"></div>
                <div className="text-center">THE ENVIRONMENT</div>
              </div>
              <div>
                <div className="text-center features-megaphone text-5xl"></div>
                <div className="text-center">COMMUNICATION</div>
              </div>
            </div>
            <p className="text-center mb-4">
              Our cleaning services are affordable and our cleaning experts are highly trained. If for any reason you
              aren't happy with our professional cleaning services, contact Time to Shine. We will come back and clean
              the specific areas that didn't meet your expectations. Nothing is more important to us than your
              satisfaction.
            </p>
          </div>
        </Container>
      </div>
    </Parallax>
  );
}
