import { Link } from 'react-router-dom';
import Container from '~/components/Layout/Container';

export default function CallNow() {
  return (
    <div className="bg-brand-900 text-brand-100 w-full">
      <Container>
        <div className="flex flex-col md:flex-row justify-around items-center py-8">
          <div className="flex items-center my-6 px-3">
            <div className="features-phone text-5xl mr-3" />
            <a href="tel:6045556565" className="flex-grow">
              <div className="text-brand-400 text-center">CALL US TODAY</div>
              <div className="text-3xl text-center">604-555-5555</div>
            </a>
          </div>

          <div className="flex items-center my-6 px-3">
            <div className="features-map text-5xl mr-3" />
            <div className="flex-grow">
              <div className="text-brand-400 text-center">SERVING</div>
              <div className="text-3xl text-center">Surrey & Langley</div>
            </div>
          </div>

          <div className="flex items-center my-6 px-3">
            <div className="features-chat text-5xl mr-3" />
            <Link to="/contact" className="flex-grow">
              <div className="text-brand-400 text-center">SEND A MESSAGE</div>
              <div className="text-3xl text-center">Visit Contact Page</div>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}
