import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useHeaderStore } from '~/store/header';
import Container from '~/components/Layout/Container';
import { ReactComponent as SVGBucket } from '~/svg/bucket.svg';
import { ReactComponent as SVGMop } from '~/svg/cleaning-mop.svg';
import { ReactComponent as SVGService } from '~/svg/cleaning-service.svg';
import { ReactComponent as SVGHouse } from '~/svg/house.svg';
import { ReactComponent as SVGAll } from '~/svg/mop-water-bucket-and-cleaning-spray.svg';
import { ReactComponent as SVGSpray } from '~/svg/spray.svg';
import { ReactComponent as SVGSweep } from '~/svg/sweep.svg';
import { ReactComponent as SVGToilet } from '~/svg/toilet.svg';
import { ReactComponent as SVGVacuum } from '~/svg/vacuum-cleaner.svg';

export default function Services() {
  const headerStore = useHeaderStore();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      headerStore.setCurrentPosition('services');
    }
  }, [inView]);

  return (
    <Container>
      <div ref={ref} id="services" className="pt-24">
        <div className="text-center text-3xl">OUR SERVICES</div>
        <div className="flex justify-center my-6">
          <div className="border-b-2 border-brand-500 w-32" />
        </div>

        <div className="flex w-full flex-wrap justify-around">
          <SVGBucket className="fill-brand-500 h-20 w-20 m-6" />
          <SVGMop className="fill-brand-500 h-20 w-20 m-6" />
          <SVGService className="fill-brand-500 h-20 w-20 m-6" />
          <SVGHouse className="fill-brand-500 h-20 w-20 m-6" />
          <SVGAll className="fill-brand-500 h-20 w-20 m-6" />
          <SVGSpray className="fill-brand-500 h-20 w-20 m-6" />
          <SVGSweep className="fill-brand-500 h-20 w-20 m-6" />
          <SVGToilet className="fill-brand-500 h-20 w-20 m-6" />
          <SVGVacuum className="fill-brand-500 h-20 w-20 m-6" />
        </div>
        <div className="my-96 h-36" />
      </div>
    </Container>
  );
}
