import clsx from 'clsx';
import type { WithChildren } from '~/types/global';

type ContainerPropTypes = WithChildren<{
  classes?: string;
}>;

const Container = ({ children, classes = '' }: ContainerPropTypes): JSX.Element => {
  return (
    <div
      className={clsx(
        'm-auto w-full px-2 xs:px-0 xs:max-w-xs xsm:max-w-xsm sm:max-w-sm smd:max-w-smd md:max-w-md mdl:max-w-mdl lg:max-w-lg lgx:max-w-lgx xl:max-w-xl xxl:max-w-xxl 2xl:max-w-2xl',
        classes,
      )}
    >
      {children}
    </div>
  );
};

export default Container;
