import React from 'react';
import { ScrollRestoration, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import useWindowScrollPosition from '~/utils/useScrollHandler';
import clsx from 'clsx';
import { useHeaderStore } from '~/store/header';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

gsap.registerPlugin(ScrollToPlugin);

const Layout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerStore = useHeaderStore();
  const scrollY = useWindowScrollPosition();

  const scrolltotopvisible = scrollY > 100 ? 'scroll-top-show' : '';
  const scrolltotop = clsx(
    'scroll-top fixed right-4 bottom-6 button-nofocus rounded-md bg-brand-500 hover:bg-brand-700',
    scrolltotopvisible,
  );

  const onMenuLinkClick = (url: string) => {
    const path = location.pathname;

    if (url === 'home') {
      if (path !== '/') {
        navigate('/');
      }
    }

    if (url === 'services') {
      if (path !== '/') {
        navigate('/');
      }
    }

    if (url === 'testimonials') {
      if (path !== '/') {
        navigate('/');
      }
    }

    if (url === 'contact') {
      if (path !== '/contact') {
        navigate('/contact');
      }
    }

    headerStore.setTargetPosition(url);

    gsap.to(window, {
      delay: 0.1,
      duration: 1.5,
      scrollTo: {
        y: `#${url === 'home' || url === 'contact' ? 'main' : url}`,
        offsetY: 60,
      },
      ease: 'elastic.out(.5,1)',
    });
  };

  return (
    <React.Fragment>
      <Header onMenuLinkClick={onMenuLinkClick} scrollY={scrollY} />
      <main id="main" className="max-content my-0 mx-auto break-word text-brandgrays-600 pt-20 md:pt-24 lg:pt-28">
        <Outlet context={onMenuLinkClick} />
        <ScrollRestoration />
      </main>
      <Footer onMenuLinkClick={onMenuLinkClick} />
      <button
        type="button"
        className={scrolltotop}
        onClick={() => {
          const element = document.getElementById('main');
          if (element) {
            // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            gsap.to(window, { duration: 1, scrollTo: '#main', ease: 'back.out(1.4)' });
          }
        }}
      >
        <div className="p-3" aria-label="Scroll to Top">
          <div className="scrollup-chevron-container">
            <div className="scrollup-chevron"></div>
            <div className="scrollup-chevron"></div>
            <div className="scrollup-chevron"></div>
          </div>
        </div>
      </button>
    </React.Fragment>
  );
};

export default Layout;
