import { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import Springer from 'springer';
import innerHeight from 'ios-inner-height';
import type { MenuLinkClickType } from '~/types/global';
import HeaderMenu from './HeaderMenu';
import Container from '~/components/Layout/Container';
// import { ReactComponent as LogoFull } from '~/svg/Logo-Full.svg';
import { ReactComponent as LogoText } from '~/svg/Logo-Text.svg';
import { ReactComponent as LogoBroom } from '~/svg/Logo-Broom.svg';

type HeaderPropTypes = {
  onMenuLinkClick: MenuLinkClickType;
  scrollY: number;
};

const Header = ({ onMenuLinkClick, scrollY }: HeaderPropTypes): JSX.Element => {
  const didMountRef = useRef(false);
  const [menuToggle, setMenuToggle] = useState(false);

  const menuSpring = Springer(0.7, 0.55);

  const closeMenu = () => {
    setMenuToggle(false);
  };
  const onMenuClick = (url: string, dropdown: boolean | undefined) => {
    if (dropdown) closeMenu();
    onMenuLinkClick(url);
  };

  useEffect(() => {
    if (didMountRef.current) {
      gsap.to('#mainmenu', {
        duration: 1.5,
        height: menuToggle ? `${innerHeight() - 80}px` : '0px',
        opacity: menuToggle ? 1 : 0,
        ease: menuSpring,
      });
    }
    didMountRef.current = true;
  }, [menuToggle]);

  return (
    <div
      id="header"
      className="transition ease-linear duration-300 fixed left-0 top-0 h-20 md:h-24 lg:h-28 z-50 flex w-full bg-white text-brand-500"
      style={{ boxShadow: '0 2px 5px 0 rgb(0 0 0 / 10%)' }}
    >
      <Container>
        <div className="flex w-full items-center justify-between px-2 smd:px-0">
          <div
            className="relative flex cursor-pointer"
            onClick={(e) => {
              onMenuClick('home', false);
            }}
          >
            {/* <LogoFull className="transition ease-linear duration-300 h-12 md:h-16 lg:h-20 my-2 fill-brand-500" /> */}
            <LogoText className="transition ease-linear duration-300 h-12 md:h-16 lg:h-20 my-2 fill-brand-500" />
            <LogoBroom className="transition ease-linear duration-300 h-12 md:h-16 lg:h-20 my-2 fill-brand-500 broom-sweep" />
          </div>
          <div className="hidden flex-grow flex-col items-end justify-center smd:flex">
            <HeaderMenu onMenuClick={onMenuClick} />
          </div>
          <div className="flex flex-grow justify-end smd:hidden">
            <button
              id="menu-button"
              className="button-nofocus ml-4"
              type="button"
              onClick={() => setMenuToggle(!menuToggle)}
            >
              <svg
                className="fill-brand-500 text-brand-500"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="27"
                viewBox="0 0 20 20"
              >
                <title>Navigation Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div id="mainmenu" className="absolute left-0 top-full z-50 w-full overflow-hidden bg-white h-0">
            <HeaderMenu dropdown onMenuClick={onMenuClick} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
