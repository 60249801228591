import Banner from './Index/Banner';
import Why from './Index/Why';
import Important from './Index/Important';
import Services from './Index/Services';
import Testimonials from './Index/Testimonials';

export default function Index(props: any) {
  return (
    <div id="home" className="relative">
      <Banner />
      <Why />
      <Important />
      <Services />
      <Testimonials />
      <div className="my-48" />
    </div>
  );
}
