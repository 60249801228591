import create from 'zustand';

type HeaderState = {
  currentposition: string;
  targetposition: string;
  setCurrentPosition: (positicurrentpositionon: string) => void;
  setTargetPosition: (targetposition: string) => void;
};

export const useHeaderStore = create<HeaderState>((set) => ({
  currentposition: 'home',
  targetposition: 'home',
  setCurrentPosition: (currentposition: string) => set((state) => ({ currentposition })),
  setTargetPosition: (targetposition: string) => set((state) => ({ targetposition })),
}));
