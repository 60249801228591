import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useHeaderStore } from '~/store/header';
import { ReactComponent as EmailSVG } from '~/svg//envelope.svg';
import { ReactComponent as PhoneSVG } from '~/svg//phone-rotary.svg';

type HeaderMenuPropTypes = {
  dropdown?: boolean;
  onMenuClick: (url: string, dropdown?: boolean) => void;
};

const HeaderMenu = ({ dropdown, onMenuClick }: HeaderMenuPropTypes): JSX.Element => {
  const headerStore = useHeaderStore();
  const location = useLocation();

  return (
    <React.Fragment>
      <div
        className={clsx(
          'flex flex-col smd:flex-row justify-around smd:justify-end h-16 smd:h-auto items-center mb-4 text-sm text-brand-500',
        )}
      >
        <a
          className="flex items-center menulink cursor-pointer hover:text-brand-600 transition-colors duration-200 px-2 md:px-3 mdl:px-4"
          href="tel:6045555555"
        >
          <PhoneSVG className="h-5 mr-2 fill-brand-500 hover:fill-brand-600" />
          604-555-5555
        </a>
        <a
          className="flex items-center menulink cursor-pointer hover:text-brand-600 transition-colors duration-200 px-2 md:px-3 mdl:px-4"
          href="mailto:ashley@timetoshinejanitorial.com"
        >
          <EmailSVG className="h-5 mr-2 fill-brand-500 hover:fill-brand-600" />
          ashley@timetoshinejanitorial.com
        </a>
      </div>
      <div
        className={clsx(
          'flex flex-grow items-center font-bold pb-24 smd:pb-0 h-full smd:h-auto -mt-16 smd:mt-0 text-brand-500',
          {
            'flex-col justify-evenly text-2xl': !!dropdown,
          },
          {
            'text-lgg': !dropdown,
          },
        )}
      >
        <div
          className={clsx(
            'menulink cursor-pointer transition-colors duration-200 px-2 md:px-3 mdl:px-4 hover:text-brand-600',
            {
              active: location.pathname === '/' && headerStore.currentposition === 'home',
            },
          )}
          onClick={() => onMenuClick('home', dropdown)}
        >
          HOME
        </div>
        <div
          className={clsx(
            'menulink cursor-pointer transition-colors duration-200 px-2 md:px-3 mdl:px-4 hover:text-brand-600',
            {
              active: location.pathname === '/' && headerStore.currentposition === 'services',
            },
          )}
          onClick={() => onMenuClick('services', dropdown)}
        >
          SERVICES
        </div>
        <div
          className={clsx(
            'menulink cursor-pointer transition-colors duration-200 px-2 md:px-3 mdl:px-4 hover:text-brand-600',
            {
              active: location.pathname === '/' && headerStore.currentposition === 'testimonials',
            },
          )}
          onClick={() => onMenuClick('testimonials', dropdown)}
        >
          TESTIMONIALS
        </div>
        <div
          className={clsx(
            'menulink cursor-pointer transition-colors duration-200 px-2 md:px-3 mdl:px-4 hover:text-brand-600',
            {
              active: location.pathname === '/contact',
            },
          )}
          onClick={() => onMenuClick('contact', dropdown)}
        >
          CONTACT
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderMenu;
