import React from 'react';
import CallNow from '~/components/CallNow';
import { ReactComponent as CleaningLady } from '~/svg/cleaning-lady.svg';
import type { MenuLinkClickType } from '~/types/global';
import Container from '../Layout/Container';

type FooterPropTypes = {
  onMenuLinkClick: MenuLinkClickType;
};
const Footer = ({ onMenuLinkClick }: FooterPropTypes): JSX.Element => (
  <React.Fragment>
    <CallNow />
    <div className="w-full  bg-brand-600 text-brand-100">
      <Container>
        <div className="flex flex-col">
          <div className="flex flex-grow flex-col smd:flex-row py-6">
            <div className="flex-grow">
              <div className="text-xl mb-2">About</div>
              <div className="flex flex-start items-center">
                <CleaningLady className="fill-white h-16 w-16 mr-6 flex-grow-0" />
                <div className="flex flex-col flex-grow">
                  <div className="text-justify mb-2">
                    Time to Shine Janitorial has quickly built a reputation as one of the leading providers of
                    commercial janitorial and cleaning solutions.
                  </div>
                  <div className="text-justify">
                    Our focus is to listen to our clients, understand their needs and provide an exceptional level of
                    janitorial service.
                  </div>
                </div>
              </div>
            </div>
            <div className="border-brand-500 border-b my-4 smd:border-b-0 smd:my-0 smd:border-r smd:mx-6" />
            <div className="flex flex-col flex-grow items-center">
              <div className="text-xl mb-2 underline">Navigate</div>
              <button type="button" onClick={() => onMenuLinkClick('home')}>
                HOME
              </button>
              <button type="button" onClick={() => onMenuLinkClick('services')}>
                SERVICES
              </button>
              <button type="button" onClick={() => onMenuLinkClick('testimonials')}>
                TESTIMONIALS
              </button>
              <button type="button" onClick={() => onMenuLinkClick('contact')}>
                CONTACT
              </button>
            </div>
          </div>
          <div className="text-center font-semibold py-2 text-brand-300 text-sm">
            Copyright &copy; 2002 Time to Shine Janitorial Inc.
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>
);

export default Footer;

/* <button type="button" onClick={() => onMenuLinkClick('home')}>
          HOME
        </button> */
