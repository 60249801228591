import logo1 from '~/img/logo-babyspinach-transparent.png';
import logo2 from '~/img/logo-broom1-babyspinach-transparent.png';
import logo4 from '~/img/logo-broom1r-babyspinach-transparent.png';
import logo5 from '~/img/logo-broom2-babyspinach-transparent.png';
import logo6 from '~/img/logo-broom2r-babyspinach-transparent.png';
import logo7 from '~/img/logo-cavan-transparent.png';
import logo8 from '~/img/logo-hillsidegrove-transparent.png';

export default function Logos() {
  return (
    <div className="relative" style={{ minHeight: '2000px' }}>
      <div
        className="bg-slate-700 flex justify-center items-end text-3xl pb-12 text-slate-300"
        style={{ height: '300px' }}
      >
        LOGO SAMPLES
      </div>
      <div className="flex flex-wrap justify-center pt-12">
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo1} className="mb-2" />
          BABY SPINACH
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo7} className="mb-2" />
          CAVAN
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo8} className="mb-2" />
          HILLSIDE GROVE
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo2} className="mb-2" />
          LOGO 1 LEFT
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo4} className="mb-2" />
          LOGO 1 RIGHT
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo5} className="mb-2" />
          LOGO 2 LEFT
        </div>
        <div className="flex flex-col items-center m-8">
          <img alt="img" src={logo6} className="mb-2" />
          LOGO 2 RIGHT
        </div>
      </div>
    </div>
  );
}
