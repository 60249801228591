import { useEffect } from 'react';
import { gsap } from 'gsap';
import Container from '~/components/Layout/Container';
import why1 from '~/img/why-2.jpg';

export default function Why() {
  useEffect(() => {
    gsap.set('#leftside', { x: -50, opacity: 0 });
    gsap.to('#leftside', {
      scrollTrigger: {
        trigger: '#leftside',
        start: 'top bottom-=100px',
        once: false,
      },
      x: 0,
      opacity: 1,
      duration: 0.7,
    });
    gsap.set('#rightside', { x: 50, opacity: 0 });
    gsap.to('#rightside', {
      scrollTrigger: {
        trigger: '#rightside',
        start: 'top bottom-=100px',
        once: false,
      },
      x: 0,
      opacity: 1,
      duration: 0.7,
    });
    gsap.set('#centerimg', { y: 50, opacity: 0 });
    gsap.to('#centerimg', {
      scrollTrigger: {
        trigger: '#centerimg',
        start: 'top bottom-=100px',
        //end: 'bottom 1px',
        once: false,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <Container>
      <div className="mb-16">
        <div className="text-center text-3xl">WHY CHOOSE US</div>
        <div className="flex justify-center my-6">
          <div className="border-b-2 border-brand-500 w-32" />
        </div>
        <div className="text-center mb-8">
          Time to Shine Janitorial operates in the Surrey/Langley area and provides a variety of cleaning services.
          Choose us because of our reputation for excellence.
        </div>
        <div className="flex flex-col smd:flex-row flex-wrap justify-center lg:justify-between items-center">
          <div id="leftside" className="flex flex-col w-full smd:w-1/2 lg:w-1/4 smd:pr-6 order-1 opacity-0">
            <div className="flex justify-center smd:justify-end text-brand-600 features-diamond text-5xl" />
            <div className="flex justify-center smd:justify-end my-4 font-semibold">SPARKLING CLEAN</div>
            <div className="flex justify-center smd:justify-end text-center smd:text-right mb-12">
              We keep your home sparkling clean and germ free. Our disinfecting process kills 99% of common bacteria and
              viruses.
            </div>
            <div className="flex justify-center smd:justify-end text-brand-600 features-umbrella text-5xl" />
            <div className="flex justify-center smd:justify-end my-4 font-semibold">INSURED & BONDED</div>
            <div className="flex justify-center smd:justify-end text-center smd:text-right mb-8">
              Our cleaners are insured and bonded so no need to worry about your apartment, office or garden.
            </div>
          </div>

          <div
            id="centerimg"
            className="smd:w-1/2 w-2/3 rounded-full overflow-hidden border-2 border-brand-500 p-4 order-3 lg:order-2 max-w-xsm opacity-0"
          >
            <div className="w-auto rounded-full overflow-hidden">
              <img src={why1} alt="why" className="w-full" />
            </div>
          </div>

          <div id="rightside" className="flex flex-col w-full smd:w-1/2 lg:w-1/4 smd:pl-6 order-2 lg:order-3 opacity-0">
            <div className="flex justify-center smd:justify-start text-brand-600 features-eco text-5xl" />
            <div className="flex justify-center smd:justify-start my-4 font-semibold">LEADING TECHNOLOGIES</div>
            <div className="flex justify-center smd:justify-start text-center smd:text-left mb-12">
              We use safe hospital-grade disinfectants, HEPA filtrations and microfiber cleaning cloths to reduce cross
              contamination.
            </div>
            <div className="flex justify-center smd:justify-start text-brand-600 features-maid text-5xl" />
            <div className="flex justify-center smd:justify-start my-4 font-semibold">RELIABLE CREW</div>
            <div className="flex justify-center smd:justify-start text-center smd:text-left mb-8">
              Our reliable and stable crews understand your specific house and office clearning service needs.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
