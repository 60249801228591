import { useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import clsx from 'clsx';
import type { MenuLinkClickType } from '~/types/global';
import Container from '../Layout/Container';

// const REGEX_PHONE = /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/;
const REGEX_EMAIL =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const regexMatches = (stringValue: string, pattern: RegExp): boolean => pattern.test(stringValue);

const toastSuccess = () => toast.success('Your message has been sent. Thank you!');
const toastError = () => toast.error('There was an error sending your message. Please try again later.');

const getRandomInteger = () => Math.floor(Math.random() * 11) + 1;

type FormDataType = {
  telephone: string;
  phone: string;
  firstname: string;
  lastname: string;
  email: string;
  location: string;
  message: string;
  captcha: string;
};

export default function Contact() {
  const formRef = useRef<HTMLFormElement>(null);
  const [sending, setSending] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDataType>();

  const { ref, ...rest } = register('phone', {
    //pattern: REGEX_PHONE,
    validate: (value) => {
      const email = getValues('email');
      const result = value.length === 14 || regexMatches(email, REGEX_EMAIL);
      // console.log('Phone Validation', { value, email, result });
      return result;
    },
  });

  const [captcha1] = useState(getRandomInteger());
  const [captcha2] = useState(getRandomInteger());

  const onMenuLinkClick = useOutletContext<MenuLinkClickType>();

  const onSubmit = handleSubmit((data) => {
    // console.log(data);
    // console.log(formRef.current);
    // telephone is a hidden dummy field to protect from bots.
    // If filled in, then invalid
    if (data.telephone !== '') return;

    sendEmail();
  });

  const sendEmail = () => {
    // e.preventDefault();
    const sid = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';
    const tid = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '';
    const key = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '';
    const form = !!formRef.current ? formRef.current : '';
    setSending(true);
    emailjs.sendForm(sid, tid, form, key).then(
      (result) => {
        // console.log('Result', result.text);
        reset();
        toastSuccess();
      },
      (error) => {
        // console.log('Error', error.text);
        toastError();
      },
    );
    setSending(false);
  };

  return (
    <Container>
      <div id="contact" className="pt-12 pl-4 pr-4 smd:pl-8:pr-8">
        <h3 className="text-center mb-0">CONTACT US</h3>
        <div className="flex justify-center my-4">
          <div className="border-b-2 border-brand-500 w-40" />
        </div>
        <p className="rounded border bg-brand-100 border-brand-600 text-sm font-normal p-3 mb-8 text-center">
          Fill out the form below and click the SEND MESSAGE button. We will respond to your request within 24 hours.
          Thank you.
        </p>
        <form ref={formRef} onSubmit={onSubmit} className="rounded border border-brand-200 bg-brand-50 py-6">
          <div className="hidden">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Phone Number
            </label>
            <input
              placeholder="Required"
              defaultValue=""
              type="text"
              className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
              {...register('telephone')}
            />
          </div>

          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 px-3 mb-8">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="firstname">
                First Name
              </label>
              <input
                placeholder="Required"
                type="text"
                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
                {...register('firstname', { required: true, min: 2, maxLength: 80 })}
              />
              {errors.firstname && <span className="text-red-600 text-sm font-normal">Please enter a first name.</span>}
            </div>
            <div className="w-full sm:w-1/2 px-3 mb-8">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="lastname">
                Last Name
              </label>
              <input
                placeholder="Required"
                type="text"
                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
                {...register('lastname', { required: true, min: 1, maxLength: 100 })}
              />
              {errors.lastname && <span className="text-red-600 text-sm font-normal">Please enter a last name.</span>}
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 px-3 mb-8">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="email">
                Email Address
              </label>
              <input
                placeholder="This or Phone Required"
                type="text"
                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
                {...register('email', {
                  validate: (value) => {
                    const phone = getValues('phone');
                    const result = phone.length === 14 || regexMatches(value, REGEX_EMAIL);
                    // console.log('Email Validation', { value, phone, result });
                    return result;
                  },
                })}
              />
              {errors.email && errors.phone && (
                <span className="text-red-600 text-sm font-normal">A valid email or phone number is required.</span>
              )}
            </div>
            <div className="w-full sm:w-1/2 px-3 mb-8">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="email">
                Phone Number
              </label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                guide={false}
                placeholder="This or Email Required"
                style={{ outlineStyle: 'none' }}
                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
                ref={(ref1: any) => {
                  if (ref1) ref(ref1.inputElement);
                }}
                {...rest}
              />
              {/* <input
              placeholder="Required"
              type="text"
              className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
              {...register('phone', { required: true, pattern: REGEX_PHONE })}
            /> */}
              {errors.email && errors.phone && (
                <span className="text-red-600 text-sm font-normal">A valid email or phone number is required.</span>
              )}
            </div>
          </div>

          <div className="w-full px-3 mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="firstname">
              Location (Surrey, Langley, etc...)
            </label>
            <input
              placeholder="Required"
              type="text"
              className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
              {...register('location', { required: true, min: 2, maxLength: 80 })}
            />
            {errors.location && (
              <span className="text-red-600 text-sm font-normal">Please enter an approximate location.</span>
            )}
          </div>

          <div className="w-full px-3 mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              rows={6}
              className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
              {...register('message', {
                validate: (value) => value !== '',
              })}
            />
            {errors.message && <span className="text-red-600 text-sm font-normal">Please enter a message.</span>}
          </div>

          <div className="w-full px-3 mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-1" htmlFor="captcha">
              Are You Human?
            </label>
            <input
              placeholder={`Required. What is ${captcha1} + ${captcha2}?`}
              type="number"
              className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring ring-brand-500"
              {...register('captcha', {
                validate: (value) => {
                  const sum = parseInt(value || '0');
                  // console.log(sum, captcha1, captcha2);
                  return sum === captcha1 + captcha2;
                },
              })}
            />
            {errors.captcha && (
              <span className="text-red-600 text-sm font-normal">{`Incorrect answer for ${captcha1} + ${captcha2}. Try again`}</span>
            )}
          </div>

          <div className="flex justify-center w-full">
            <button
              type="submit"
              disabled={sending}
              className={clsx(
                { 'cursor-not-allowed': sending },
                'transition-colors inline-flex justify-center items-center bg-brand-500 text-white hover:bg-brand-600 uppercase text-sm h-12 px-4 py-2 shadow hover:shadow-lg outline-none focus:outline-none',
              )}
              style={{ minWidth: '200px' }}
            >
              {sending ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <span className="features-email text-2xl mr-4" />
              )}
              Send Message
            </button>
          </div>
        </form>
        <div className="flex justify-center">
          <button
            type="button"
            className="mt-12 mb-16 border rounded flex items-center justify-center px-4 py-2 bg-brand-700 text-white hover:bg-brand-800"
            onClick={() => onMenuLinkClick('home')}
          >
            <span className="features-home-2 text-2xl mr-4" />
            Return to Home Page
          </button>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            duration: 5000,
          }}
        />
      </div>
    </Container>
  );
}
