import { Link } from 'react-router-dom';
import Container from '../Layout/Container';
import FOF from '~/img/404.gif';

export default function FourOhFour() {
  return (
    <Container>
      <div id="404" className="pt-36 pl-4 pr-4 smd:pl-8:pr-8">
        <Link to="/">
          <div className="relative flex flex-col items-center justify-center">
            <img src={FOF} alt="404" />
            <div className="absolute left-0 top-0 flex flex-col items-center w-full">
              <h3 className="text-center mb-0">PAGE NOT FOUND</h3>
              <div className="flex justify-center my-4">
                <div className="border-b-2 border-brand-500 w-40" />
              </div>
              <h5>Click on the image to return to the HOME page.</h5>
            </div>
          </div>
        </Link>
      </div>
    </Container>
  );
}
