import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import Slider from 'react-carousel-responsive';
import { useInView } from 'react-intersection-observer';
import { useHeaderStore } from '~/store/header';
import { ReactComponent as Quote } from '~/svg/quote.svg';
import Container from '~/components/Layout/Container';
import type { TestimonialDataType, TestimonialsDataType } from '~/types/global';
import importantImg from '~/img/bg-2.jpg';

export default function Testimonials() {
  const headerStore = useHeaderStore();
  const data: TestimonialsDataType = useLoaderData() as TestimonialsDataType;

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      headerStore.setCurrentPosition('testimonials');
    }
  }, [inView]);

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  const listTestimonials = () => {
    return data.data.testimonials?.map((testimonial: TestimonialDataType, index: number) => (
      <div key={index} className="px-8 h-48 flex flex-col justify-center items-center">
        <div className="w-2/3 text-center">{testimonial.message}</div>
        <div className="w-24 border-b-2 border-white h-5 mb-4" />
        <div className="italic font-bold">{`${testimonial.name} - ${testimonial.location}`}</div>
      </div>
    ));
  };

  return (
    <div ref={ref} id="testimonials" className="pt-24 mb-16">
      <div className="text-center text-3xl">TESTIMONIALS</div>
      <div className="flex justify-center my-6">
        <div className="border-b-2 border-brand-500 w-32" />
      </div>
      <Parallax
        bgImage={importantImg}
        strength={500}
        bgImageStyle={{ maxWidth: 'none', opacity: '.2' }}
        className="bg-brand-900"
      >
        <div style={{ height: '350px' }}>
          <Container>
            <div className="mdqq:px-24 xxs:-mx-5 py-12 min-h-screen text-white">
              <div className="flex justify-center">
                <Quote className="fill-white h-12" />
              </div>
              {!!data && !data.error ? (
                <Slider autoplay={true} autoplaySpeed={5000} pauseOnHover={true}>
                  {listTestimonials()}
                </Slider>
              ) : (
                <div className="px-8 h-48 flex flex-col justify-center items-center">COMING SOON</div>
              )}
            </div>
          </Container>
        </div>
      </Parallax>
    </div>
  );
}
